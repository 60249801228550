import { Menu } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Innstance } from "../../Axios";
import { useDispatch } from "react-redux";
import { getUser } from "../../store/User/action";
import { useLocation } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();

  const logOut = () => {
    Innstance.post("/admin/auth/logout")
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(getUser(null));
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <nav className="nav">
      <img src={require("../../assets/img/logo.jpg")} alt="" title="Logo" />
      <Menu
        className="menu"
        theme="light"
        mode="inline"
        defaultSelectedKeys={
          location.pathname.includes("ushers")
            ? ["3"]
            : location.pathname.includes("events")
            ? ["2"]
            : location.pathname.includes("/hiringUshers")
            ? ["4"]
            : location.pathname.includes("/leaders")
            ? ["5"]
            : location.pathname.includes("/transactions")
            ? ["6"]
            : ["1"]
        }
      >
        <Menu.Item
          className="item"
          key="1"
          icon={
            <img src={require("../../assets/img/Dashboard.jpg")} alt="Logo" />
          }
        >
          Dashboard
          <Link to={"/"} />
        </Menu.Item>

        <Menu.Item
          className="item"
          key="2"
          icon={<img src={require("../../assets/img/events.png")} alt="Logo" />}
        >
          Events
          <Link to={"/events"} />
        </Menu.Item>

        <Menu.Item
          className="item"
          key="3"
          icon={<img src={require("../../assets/img/ushers.jpg")} alt="Logo" />}
        >
          Approved ushers
          <Link to={"/ushers"} />
        </Menu.Item>
        <Menu.Item
          className="item"
          key="4"
          icon={<img src={require("../../assets/img/hiring.jpg")} alt="Logo" />}
        >
          Pool of ushers
          <Link to={"/hiringUshers"} />
        </Menu.Item>
        <Menu.Item
          className="item"
          key="5"
          icon={
            <img src={require("../../assets/img/profile.jpg")} alt="Logo" />
          }
        >
          Project coordinators
          <Link to={"/leaders"} />
        </Menu.Item>
        <Menu.Item
          className="item"
          key="6"
          icon={
            <img
              src={require("../../assets/img/transactions.jpg")}
              alt="Logo"
            />
          }
        >
          Financial transactions
          <Link to={"/transactions"} />
        </Menu.Item>

        <Menu.Item
          className="item"
          key="11"
          icon={
            <img
              src={require("../../assets/img/transactions.jpg")}
              alt="Logo"
            />
          }
        >
          Privacy & Policy
          <Link to={"/privacy"} />
        </Menu.Item>

        <Menu.SubMenu
          key="7"
          icon={
            <img src={require("../../assets/img/settings.jpg")} alt="Logo" />
          }
          title="Settings"
        >
          <Menu.Item key="8">
            Project category
            <Link to={"/categories"} />
          </Menu.Item>
          <Menu.Item key="9">
            Manpower categories
            <Link to={"/types"} />
          </Menu.Item>
          <Menu.Item key="10">
            <Link to={"admin"} />
            Admin
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
      <h5 className="logout ptr">
        <img
          src={require("../../assets/img/logout.jpg")}
          alt=""
          title="logout"
        />
        <span onClick={logOut}>Logout</span>
      </h5>
    </nav>
  );
};

export default NavBar;
